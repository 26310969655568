import { mapActions, mapState } from 'vuex';
import Swal from 'sweetalert2';
import { $EventBus } from '@/main';
import FormHeader from '@/components/inspections/formats/hseq-sso1-f-255/headers/FormHeader.vue';
import FormBody from '@/components/inspections/formats/hseq-sso1-f-255/bodies/FormBody.vue';
import FormImprovement from '@/components/inspections/formats/hseq-sso1-f-255/improvements/FormImprovement.vue';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import ResponsibleInpection from '@/components/global/ResponsibleInpection.vue';
import textTransform from '@/helpers/textTransform.js';

export default {
	name: 'RegistersDraftHseqSso1F255',
	data: () => ({
		steps: {
			current: 1,
			views: [1],
		},
		headers: [
			{
				text: 'Nombre',
				value: 'name',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Evaluación',
				value: 'evaluation',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
		],
		validationErrors: [],
		improvements: [],
		isLoadingData: false,
		isLoadingSave: false,
		isLoadingDraft: false,
		renderBodies: false,
		renderQuestions: false,
		renderImprovements: false,
	}),
	computed: {
		...mapState('inspection', ['inspection']),
		...mapState('register', ['register']),
		...mapState('security', ['managements', 'costCenters']),
		breadcrumbs: function () {
			return [
				{
					text: 'Inspecciones',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'InspectionsList',
					},
				},
				{
					text: this.inspection
						? textTransform(this.inspection.name)
						: this.$route.params.inspectionId,
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'InspectionsRegisterList',
						params: { id: this.$route.params.inspectionId },
					},
				},
				{
					text: 'Registro',
					disabled: true,
					href: '/',
				},
			];
		},
		totalQuestions: function () {
			let total = 0;
			this.inspection.bodies.forEach((body) => {
				total += body.items.length;
			});
			return total;
		},
		totalItemsApply: function () {
			let total = 0;
			if (this.inspection) {
				this.inspection.bodies.forEach((group) => {
					group.items.forEach((item) => {
						if (['SI', 'NO'].includes(item.value)) {
							total++;
						}
					});
				});
			}
			return total;
		},
		totalItemsResponse: function () {
			let total = 0;
			if (this.inspection) {
				this.inspection.bodies.forEach((group) => {
					group.items.forEach((item) => {
						if (item.value && item.value != '') {
							total++;
						}
					});
				});
			}
			return total;
		},
		optionsAnswer: function () {
			let options = [];
			if (this.inspection) {
				this.inspection.bodies.forEach((body) => {
					body.options_answer.forEach((option) => {
						let exists = false;
						options.forEach((optionItem) => {
							if (optionItem.value == option.value) {
								exists = true;
							}
						});
						if (!exists) {
							options.push({
								label: option.label,
								value: option.value,
								color: option.color,
								total: 0,
							});
						}
					});
				});

				// SET TOTALS
				this.inspection.bodies.forEach((body) => {
					body.items.forEach((item) => {
						options.forEach((optionItem, indexOI) => {
							if (item.value == optionItem.value) {
								options[indexOI].total++;
							}
						});
					});
				});
			}
			return options;
		},
		totalAnswers: function () {
			let total = 0;
			this.optionsAnswer.forEach((option) => {
				total += option.total;
			});
			return total;
		},
		completeImprovements: function () {
			let result = true;
			this.improvements.forEach((improvement) => {
				if (
					!improvement ||
					!improvement.criticity ||
					!improvement.finding ||
					improvement.finding == '' ||
					improvement.criticity == ''
				) {
					result = false;
				}
			});
			return result;
		},
		validateQuestions: function () {
			let result = true;
			this.inspection.bodyQuestions.forEach((group) => {
				group.items.forEach((item) => {
					if (
						(!item.value || item.value === undefined || item.value == '') &&
						item.required
					) {
						result = false;
					}
				});
			});
			return result;
		},
		validateHeader: function () {
			let result = true;
			if (this.inspection?.headers && Array.isArray(this.inspection?.headers)) {
				this.inspection.headers.forEach((header) => {
					if (
						!header.value ||
						header.value === undefined ||
						header.value == ''
					) {
						result = false;
					}
				});
			}
			return result;
		},
		completedQuestionary: function () {
			return (
				this.totalItemsResponse == this.totalQuestions &&
				this.totalItemsResponse > 0 &&
				this.validateHeader &&
				this.completeImprovements &&
				this.validateQuestions
			);
		},
		isEditable: function () {
			return !(
				this.register.status === 'DRAFT' &&
				this.$route.name == 'InspectionsRegisterDraft'
			);
		},
	},
	created() {
		this.initialize();
	},
	methods: {
		...mapActions('inspection', ['find', 'cleanInspection']),
		...mapActions('register', [
			'create',
			'draft',
			'findById',
			'update',
			'cleanRegister',
			'pdf',
			'updateStatus',
		]),

		textTransform,

		initialize() {
			$EventBus.$emit('showSubNav', false);
			this.isLoadingData = true;
			this.find({
				success: () => {
					this.getRegister();
				},
				fail: () => {
					this.isLoadingData = false;
				},
				id: this.$route.params?.inspectionId,
			});
		},
		getRegister() {
			this.findById({
				success: () => {
					this.setExistValues();
					this.isLoadingData = false;
				},
				fail: () => {
					this.isLoadingData = false;
				},
				params: {
					inspectionId: this.$route.params?.inspectionId,
					registerId: this.$route.params?.id,
				},
			});
		},
		setExistValues() {
			const headers = this.register?.registerHeaders || null;
			const bodies = this.register?.bodyRegisters || null;
			const questions = this.register?.bodyQuestionRegisters || null;
			const improvements = this.register?.bodyImprovementRegisters || null;

			if (this.register?.status != 'PENDING') {
				this.headers.push({
					text: 'Acciones',
					value: 'actions',
					sortable: false,
					align: 'end',
					class: 'primary--text',
				});
			}

			if (Array.isArray(headers)) {
				headers.forEach((header) => {
					this.$store.dispatch('inspection/updateInputHeader', {
						id: header.header_id,
						value:
							header.value && header.value != '' && !isNaN(header.value)
								? parseInt(header.value)
								: header.value,
						disabled: this.isEditable,
					});
				});
			}

			if (Array.isArray(bodies)) {
				bodies.forEach((body) => {
					this.$store.dispatch('inspection/updateInputBody', {
						id: body.body_id,
						value: body.compliance ? body.compliance : null,
					});
				});
				this.renderBodies = true;
			}

			if (Array.isArray(questions)) {
				questions.forEach((question) => {
					this.$store.dispatch('inspection/updateInputQuestion', {
						id: question.body_question_id,
						value: question.value,
					});
				});
				this.renderQuestions = true;
			}

			if (Array.isArray(improvements)) {
				improvements.forEach((improvement) => {
					this.$store.dispatch('inspection/updateInputImprovement', {
						id: improvement.body_id,
						criticity: improvement.criticity,
						finding: improvement.finding,
						body_id: improvement.body_id,
					});
				});
				this.renderImprovements = true;
			}
		},
		validateImprovement() {
			let result = true;
			this.improvements.forEach((improvement) => {
				if (
					!improvement.criticity ||
					!improvement.finding ||
					improvement.criticity === undefined ||
					improvement.finding === undefined ||
					improvement.criticity == '' ||
					improvement.finding == ''
				) {
					result = false;
				}
			});
			return result;
		},
		saveRegister(status) {
			try {
				const service_id = this.findValueInArray({
					items: this.inspection?.headers,
					prop: 'key',
					val: 'service',
					propFound: 'value',
				});
				let data = {
					register: {
						register_id: this.$route.params.id,
						inspection_id: this.inspection.id,
						format: this.inspection.format,
						service_id,
						status,
						date: this.findValueInArray({
							items: this.inspection?.headers,
							prop: 'key',
							val: 'date',
							propFound: 'value',
						}),
						manager_id: this.findValueInArray({
							items: this.inspection?.headers,
							prop: 'key',
							val: 'manager',
							propFound: 'value',
						}),
						management_id: this.findValueInArray({
							items: this.costCenters,
							prop: 'id',
							val: service_id,
							propFound: 'management_id',
						}),
						client_id: this.findValueInArray({
							items: this.inspection?.headers,
							prop: 'key',
							val: 'company',
							propFound: 'value',
						}),
						company_id: parseInt(localStorage.getItem('company_id')),
					},
					headers: [],
				};
				data.headers = this.inspection.headers.map((header) => {
					return {
						header_id: header.id,
						value: (header.value || '').toString() || null,
					};
				});
				this.sendRegister(data);
			} catch (error) {
				console.error(error);
			}
		},
		sendRegister(data) {
			this.validationErrors = [];
			this.create({
				success: (response) => {
					if (response.data.code === 200) {
						this.$router.push({
							name: 'InspectionsRegisterList',
							params: { id: this.inspection.format },
						});
						$EventBus.$emit(
							'showSnack',
							'success',
							'Se ha registrado satisfactoriamente.'
						);
					}
				},
				fail: (error) => {
					const { response } = error;
					if (response && response?.status == 400 && response?.data?.message) {
						this.validationErrors = Array.isArray(response?.data?.message)
							? response?.data?.message
							: [];
					} else {
						this.validationErrors = [
							'Algo salio mal, no se guardo la información.',
						];
					}
				},
				data,
			});
		},
		findValueInArray({ items = [], prop = null, val = null, propFound = null }) {
			let found;
			if (Array.isArray(items) && prop && val) {
				found = items.find((item) => item[prop] == val);
				if (found && propFound) {
					found = found[propFound] ? found[propFound] : null;
				} else {
					found = null;
				}
			}
			return found || null;
		},
		validateGroup(index) {
			let result = true;
			this.inspection.bodies[index].items.forEach((body) => {
				if (!body.value || body.value === undefined || body.value === '') {
					result = false;
				}
			});
			return result;
		},
		validateQuestion(index) {
			let result = true;
			this.inspection.bodyQuestions[index].items.forEach((body) => {
				if (!body.value || body.value === undefined || body.value == '') {
					result = false;
				}
			});
			return result;
		},
		setImprovement(data) {
			this.improvements = data;
		},
		groupData(status) {
			// INITIAL DATA
			const service_id = this.findValueInArray({
				items: this.inspection?.headers,
				prop: 'key',
				val: 'service',
				propFound: 'value',
			});

			let dataSend = {
				register: {
					company_id: parseInt(localStorage.getItem('company_id')),
					register_id: this.register ? this.register.id : null,
					inspection_id: this.inspection.id,
					format: this.inspection.format,
					service_id,
					status,
					date: this.findValueInArray({
						items: this.inspection?.headers,
						prop: 'key',
						val: 'date',
						propFound: 'value',
					}),
					manager_id: this.findValueInArray({
						items: this.inspection?.headers,
						prop: 'key',
						val: 'manager',
						propFound: 'value',
					}),
					management_id: this.findValueInArray({
						items: this.costCenters,
						prop: 'id',
						val: service_id,
						propFound: 'management_id',
					}),
				},
				headers: [],
				bodies: [],
				bodyQuestions: [],
				improvements: this.improvements,
			};

			// SET HEADERS
			dataSend.headers = this.inspection.headers.map(function (header) {
				return { header_id: header.id, value: (header?.value || '').toString() };
			});

			// SET BODIES
			this.inspection.bodies.forEach((body) => {
				body.items.forEach((item) => {
					dataSend.bodies.push({
						compliance: item.value,
						observations: item.observations,
						body_id: item.id,
					});
				});
			});

			// SET QUESTIONS
			this.inspection.bodyQuestions.forEach((group) => {
				group.items.forEach((item) => {
					dataSend.bodyQuestions.push({
						value: item.value,
						body_question_id: item.id,
					});
				});
			});

			return dataSend;
		},
		send(status = 'PENDING') {
			if (status == 'PENDING') {
				this.isLoadingSave = true;
			} else {
				this.isLoadingDraft = true;
			}
			this.update({
				success: () => {
					this.isLoadingSave = false;
					this.isLoadingDraft = false;
					this.$router.push(
						'/dashboard/inspections/' + this.inspection.format + '/registers'
					);
					$EventBus.$emit(
						'showSnack',
						'success',
						'Se ha registrado satisfactoriamente.'
					);
				},
				fail: (error) => {
					this.isLoadingSave = false;
					this.isLoadingDraft = false;
					const { response } = error;
					if (response && response?.data?.message) {
						this.validationErrors = Array.isArray(response?.data?.message)
							? response?.data?.message
							: [];
					} else {
						this.validationErrors = [
							'Algo salio mal, no se guardo la información.',
						];
					}
				},
				data: this.groupData(status),
			});
		},
		setQuestionResponse(e, id) {
			this.$store.dispatch('inspection/updateInputQuestion', {
				id: id,
				value: e,
			});
		},
		getLinkPdf(id) {
			let link = '';
			this.pdf({
				success: (response) => {
					link = response;
				},
				fail: () => {},
				id: id,
			});
			return link;
		},
		backPage() {
			if (this.$route.name == 'InspectionsRegisterRevision') {
				this.$router.push({
					name: 'InspectionsRegisterListRevision',
				});
			} else {
				this.$router.push({
					name: 'InspectionsRegisterList',
					params: {
						id: this.$route.params.inspectionId,
					},
				});
			}
		},
		approveInspection() {
			Swal.fire({
				title: '¿Está seguro que desea aprobar esta inspección?',
				text: 'Confirme acción',
				icon: 'question',
				reverseButtons: true,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'No, Cancelar',
				confirmButtonText: '¡Si, Aprobar!',
			}).then((result) => {
				if (result.value) {
					this.isLoadingSave = true;
					this.updateStatus({
						success: () => {
							this.isLoadingSave = false;
							this.backPage();
							$EventBus.$emit(
								'showSnack',
								'success',
								'Se ha registrado satisfactoriamente.'
							);
						},
						fail: (error) => {
							this.isLoadingSave = false;
							const { response } = error;
							if (Array.isArray(response?.data?.message)) {
								this.validationErrors = response?.data?.message;
							}
							$EventBus.$emit(
								'showSnack',
								'error',
								'Algo salio mal, no se guardo la información.'
							);
						},
						data: { format: this.inspection.format, id: this.register.id },
					});
				}
			});
		},
	},
	components: {
		FormHeader,
		FormBody,
		FormImprovement,
		ValidationAlert,
		ResponsibleInpection,
	},
	destroyed() {
		this.cleanRegister();
		this.cleanInspection();
	},
};
